<template>
    <layout-centered max-width="800px">
        <div id="form-signin" class="bg-dark text-white rounded-3 shadow-lg d-flex" style="position: relative">
            <div class="logo align-self-center">
                <img :alt="appTitle" class="d-block mx-auto" src="@/assets/img/logo.png">
            </div>

            <form v-if="!passwordReset && !passwordResetSent" class="form" @submit.prevent="onSignIn">
                <h1 class="h3 text-center">{{ $t('pages.sign-in.heading') }}</h1>
                <p class="text-center">{{ $t('pages.sign-in.subheading') }}</p>

                <div class="mb-2">
                    <label class="form-label" for="email">{{ $tc('email', 1) }}</label>
                    <input id="email"
                           v-model="email"
                           :class="{ 'is-invalid': v.email.$error }"
                           :placeholder="$tc('email', 1)"
                           class="form-control"
                           type="text">
                    <div v-if="v.$dirty && !v.email.required" class="invalid-feedback">
                        {{ $t('validation.error.required.email') }}
                    </div>
                    <div v-if="v.$dirty && v.email.required && !v.email.invalid" class="invalid-feedback">
                        {{ $t('validation.error.invalid.email') }}
                    </div>
                </div>

                <div class="mb-2">
                    <label class="form-label" for="password">{{ $tc('password', 1) }}</label>
                    <input id="password"
                           v-model="password"
                           :class="{ 'is-invalid': v.password.$error }"
                           :placeholder="$tc('password', 1)"
                           class="form-control"
                           type="password">
                    <div v-if="v.$dirty && !v.password.required" class="invalid-feedback">
                        {{ $t('validation.error.required.password') }}
                    </div>
                </div>

                <div class="mb-4">
                    <label class="form-label" for="language">{{ $tc('language', 1) }}</label>
                    <select id="language" v-model="$i18n.locale" class="form-select">
                        <option value="en-US">{{ $t('languages.en-US') }}</option>
                        <option value="de-DE">{{ $t('languages.de-DE') }}</option>
                    </select>
                </div>

                <button class="btn btn-lg w-100 btn-primary rounded-pill mb-2"
                        type="submit">
                    {{ $t('actions.sign-in') }}
                </button>

                <div class="text-center">
                    <button class="btn btn-link link-light" type="button" @click="passwordReset = true">
                        {{ $t('actions.forgot-password') }}
                    </button>
                </div>
            </form>

            <form v-if="passwordReset && !passwordResetSent" class="form" @submit.prevent="onResetPassword">
                <h1 class="h3 text-center">{{ $t('actions.reset-password') }}</h1>
                <p class="text-center">{{ $t('pages.sign-in.reset-password-description') }}</p>

                <div class="mb-4">
                    <label class="form-label" for="reset-email">{{ $tc('email', 1) }}</label>
                    <input id="reset-email"
                           v-model="email"
                           :placeholder="$tc('email', 1)"
                           class="form-control"
                           required="required"
                           type="text">
                </div>
                <button class="btn btn-lg w-100 btn-primary rounded-pill mb-2"
                        type="submit">
                    {{ $t('actions.submit') }}
                </button>

                <div class="text-center">
                    <button class="btn btn-link link-light"
                            type="button"
                            @click.prevent="passwordReset = false;">
                        {{ $t('pages.sign-in.sign-in-instead') }}
                    </button>
                </div>
            </form>

            <div v-if="passwordResetSent" class="px-4 py-5">
                <h1 class="h3 text-center">{{ $t('pages.sign-in.email-sent-successfully') }}</h1>
                <p>{{ $t('pages.sign-in.reset-password-submitted') }}</p>
                <div class="text-center">
                    <button class="btn btn-primary"
                            type="button"
                            @click="passwordResetSent = false; passwordReset = false;">
                        {{ $t('actions.back') }}
                    </button>
                </div>
            </div>

            <component-loading v-if="loading"/>
        </div>
    </layout-centered>
</template>

<script>

import LayoutCentered from "@/layouts/LayoutCentered";
import ComponentLoading from "@/components/ui/ComponentLoading";
import {email, required} from "vuelidate/lib/validators";
import getCookie from "@/lib/get-cookie";
import setCookie from "@/lib/set-cookie";

export default {
    name: "PageSignIn",
    components: {ComponentLoading, LayoutCentered},
    computed: {
        appTitle() {
            return process.env.VUE_APP_TITLE;
        },
        v() {
            return this.$v;
        }
    },
    data() {
        return {
            loading: false,
            error: false,
            email: '',
            password: '',
            passwordReset: false,
            passwordResetSent: false
        }
    },
    methods: {
        async onSignIn() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.error = true;

            this.$store.dispatch('USER/LOGIN', {username: this.email, password: this.password})
                .then(() => {
                    setCookie('locale', this.$i18n.locale);
                    this.$router.push({name: 'dashboard-overview'});
                    this.$gtag.event('USER_LOGIN');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.login-failed'),
                        message: this.$tc('toast.danger.login-failed')
                    });
                    this.loading = false;
                    this.error = true;
                    Promise.reject(error);
                });
        },
        onResetPassword() {
            this.loading = true;
            this.$store.dispatch('USER/LOST_PASSWORD', this.email)
                .then(() => {
                    this.passwordResetSent = true;
                    this.loading = false;
                    this.$gtag.event('USER_LOST_PASSWORD');
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.password-reset-failed')
                    });
                    this.loading = false;
                })
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    },
    beforeMount() {
        let locale = 'en-US';
        let localeCookie = getCookie('locale', false);

        if (localeCookie && ['en-US', 'de-DE'].includes(localeCookie)) {
            locale = localeCookie;
        }

        this.$i18n.locale = locale;
    }
}
</script>

<style lang="scss" scoped>

#form-signin {
    .logo {
        flex-basis: 50%;
        padding: 2.5rem 2.5rem 2.5rem 1.5rem;
    }

    .form {
        flex-basis: 50%;
        padding: 2.5rem 1.5rem 2.5rem 2.5rem;
    }
}

</style>
